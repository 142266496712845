import React, { useState, useEffect, useCallback } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { decipherToken, removeToken, setToken } from './services/login';
import { BASE_URL, getCompanies, filterOptOut, filterTrackingData, isTokenValid, isUserConsumer, isUserMerchant, USER_TYPES } from './services/utils';
import styled from 'styled-components';
import Theme from './Theme';
import Loading from 'react-loading';
import Login from './components/Login';
import ConsumerDashboard from './components/consumer/ConsumerDashboard';
import MerchantDashboard from './components/merchant/MerchantDashboard';
import Nav from './components/Nav';
import Signup from './components/Signup';
import SignupMerchant from './components/SignupMerchant';
import ResetFailed from './components/ResetFailed';
import ResetConfirmation from './components/ResetConfirmation';
import ResetPassword from './components/ResetPassword';
import ResetExpired from './components/ResetExpired';
import isLoading from './components/common/isLoading';
import Terms from './components/Terms';
import Account from './components/Account';
import NoPage from './components/NoPage';
import './App.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-google-places-autocomplete/dist/index.min.css';
import './DataTable.css';
import Modal from 'react-modal';
import { modalStyles, modalStylesI, Mobile } from './Theme';
import { library } from '@fortawesome/fontawesome-svg-core';
import googleApp from './images/google-app.png';
import appleApp from './images/apple-app.svg';
import { isIOS, isAndroid } from 'react-device-detect';
import {
  faCheckSquare,
  faPlus,
  faQuestionCircle,
  faCheckCircle,
  faIdCard,
  faSearch,
  faTimes,
  faCloudDownloadAlt,
  faSortDown,
  faSortUp,
  faSort,
  faEdit,
  faEnvelopeOpenText,
  faFilter,
  faChartBar,
  faUsers,
  faUser,
  faSyncAlt,
  faCode,
  faNetworkWired,
  faDownload,
  faChartLine,
  faCalendar,
  faUserCheck,
  faInfoCircle,
  faCreditCard,
  faGift,
  faBullseye,
  faPlusSquare,
  faTags,
  faMoneyCheck,
  faCheck,
  faTrashAlt,
  faMapMarkerAlt,
  faExclamationTriangle,
  faTachometerAlt,
  faChalkboard
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
  faChalkboard,
  faCheckSquare, 
  faPlus, 
  faQuestionCircle,  
  faCheckCircle,
  faIdCard,
  faSearch,
  faTimes,
  faCloudDownloadAlt,
  faSortDown,
  faSortUp,
  faSort,
  faEdit,
  faEnvelopeOpenText,
  faFilter,
  faChartBar,
  faUsers,
  faUser,
  faSyncAlt,
  faCode,
  faNetworkWired,
  faDownload,
  faChartLine,
  faCalendar,
  faUserCheck,
  faInfoCircle,
  faCreditCard,
  faGift,
  faBullseye,
  faPlusSquare,
  faTags,
  faMoneyCheck,
  faCheck,
  faTrashAlt,
  faMapMarkerAlt,
  faExclamationTriangle,
  faTachometerAlt
  );

function App({ setLoading }) {
  const [view, setView] = useState('overview');
  const [tokenState, setTokenState] = useState('expired');
  const [webToken, setWebToken] = useState(null);
  const [message, setMessage] = useState('');
  const [dashboardData, setDashboardData] = useState(null);
  const [cardData, setCardData] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [companyList, setCompanyList] = useState(null);
  const [optInData, setOptInData] = useState(null);
  const [userType, setUserType] = useState(null);
  const [trackingData, setTrackingData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [offersData, setOffersData] = useState(null);
  const [offersDataHistory, setOffersDataHistory] = useState(null);
  const [errorStatus, setErrorStatus] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [emailSt, setEmailSt] = useState('');
  const [creditInfo, setCreditInfo] = useState(null);
  const [pdfData, setPDFData] = useState(null);
  const [contactUs, setContactUs] = useState(false);
  const [resetPw, setResetPw] = useState(false);
  const [mobileModal, setMobileModal] = useState(true);

  const handleLogout = useCallback(() => {
    removeToken();
    setTokenState(null);
    setWebToken(null);
    setCompanyList(null);
    setDashboardData(null);
    setCardData(null);
    setAccountData(null);
    setOptInData(null);
    setUserType(null);
    setTrackingData(null);
    setProfileData(null);
    setOffersData(null);
    setMessage('');
    setView('overview');
  }, [])

  const fetchDashboardData = useCallback(({ token }) => {
    fetch(BASE_URL + '/consumers/subscribers', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          setMessage(`Error ${res.status}, please log in again`);
          removeToken();
          handleLogout();
        }
      })
      .then(data => {
        setDashboardData({
          accounts: data.accounts,
          cards: data.cards,
          income: data.income,
        });
        setOptInData(filterOptOut(data));
        setCompanyList(getCompanies(data));
        setTrackingData(filterTrackingData(data));
      })
      .catch(err => {
        console.log("error fetching data", err)
        // setMessage(`There was an error, please log in again`);
        setErrorStatus(true);
        handleLogout();
      })
  }, [handleLogout])

  const fetchCardsData = useCallback(({ token }) => {
    fetch(BASE_URL + '/cards', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          removeToken();
          handleLogout();
        }
      })
      .then(data => {
        setCardData(data.cards);
      })
      .catch(err => {
        setErrorStatus(true);
        console.log("Error fetching card data", err);
      })
  }, [handleLogout])

  const fetchAccountsData = useCallback(({ token }) => {
    fetch(BASE_URL + '/accounts', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          removeToken();
          handleLogout();
        }
      })
      .then(data => {
        setAccountData(data.accounts);
      })
      .catch(err => {
        setErrorStatus(true);
        console.log("Error fetching account data", err);
      })
  }, [handleLogout])

  const fetchOffersData = useCallback(({ token, type }) => {
    fetch(`${BASE_URL}/${type}/offers`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          removeToken();
          handleLogout();
        }
      })
      .then(data => {
        if (type === 'consumers') {
          setOffersData({
            offers: data.offers
          });
        }
        if (type === 'merchants') {
          setDashboardData(data)
        }
      })
      .catch(err => {
        setErrorStatus(true);
        console.log("Error fetching offer data", err);
      })
  }, [handleLogout])

  const fetchOffersHistory = useCallback(async ({ token }) => {
    try {
      const res = await fetch(`${BASE_URL}/consumers/linked-offers`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        })
      })
      if (res.status === 200) {
        const data = await res.json()
        setOffersDataHistory(data);
      }
    }
    catch(e) {
      console.log("Error fetching offers history", e)
      setErrorStatus(true);
    }
  }, [setOffersDataHistory, setErrorStatus]);

  const fetchCreditInfo = useCallback(({ token }) => {
    fetch(BASE_URL + '/consumers/credit', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (data) {
          setCreditInfo(data);
        }
      })
      .catch(err => {
        // setErrorStatus(true);
        // handleLogout();
        console.log("Error fetching credit info", err);
      })
  }, [])

  const fetchProfileData = useCallback(({ token, endpoint='consumers' }) => {
    fetch(`${BASE_URL}/${endpoint}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          removeToken();
          handleLogout();
        }
      })
      .then(data => {
        if (isUserConsumer(userType)) {
          setProfileData({
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            address: data.address,
            city: data.city,
            country: data.country,
            state: data.state,
            postalCode: data.postal_code,
            phone: data.phone,
            id: data.id,
            billing: data.billing,
            verification: data.verification,
            subscription: data.subscription,
            mfa_enabled: data.mfa_enabled
          });
          if (data.verification.tu_verified) {
            fetchCreditInfo({ token })
          }
        }
        if (isUserMerchant(userType)) {
          setProfileData({
            email: data.email,
            phone: data.phone,
            id: data.id,
            companyAddress: data.company_address,
            companyCity: data.company_city,
            companyCountry: data.company_country,
            companyName: data.company_name,
            companyPostalCode: data.company_postal_code,
            companyState: data.company_state,
            billing: data.billing,
            subscription: data.subscription,
            mfa_enabled: data.mfa_enabled
          })
        }
      })
      .catch(err => {
        setErrorStatus(true);
        handleLogout();
        console.log("Error fetching profile data", err);
      })
  }, [handleLogout, userType, fetchCreditInfo])

  const fetchPDFdata = useCallback(({ token }) => {
    fetch(BASE_URL + '/consumers/data-portability', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (data) {
          setPDFData(data.files);
        }
      })
      .catch(err => {
        setErrorStatus(true);
        handleLogout();
        console.log("Error fetching credit info", err);
      })
  }, [handleLogout])

  // Check if token is expired and returns token to be used for other functions.

  const getToken = useCallback(async () => {
    let token = localStorage.getItem('token');
    let nowInSeconds = Date.now() / 1000;
    let expiredToken = () => { setTokenState('expired'); return ({ tokenStatus: 'expired' }); }

    if (!token) {
      setTokenState('login')
      return
    }

    const payload = JSON.parse(atob(token.split('.')[1]));
    let userType = payload.type;
    setUserType(userType);

    if (payload.exp >= nowInSeconds) {
      setWebToken(token);
      setTokenState('valid');
      return ({ token, tokenStatus: 'valid' });
    }

    localStorage.removeItem('token');
    let refresh_token = localStorage.getItem('refresh token');

    if (!refresh_token) {
      return expiredToken;
    }

    const refresh_payload = JSON.parse(atob(refresh_token.split('.')[1]));
    if (refresh_payload.exp < nowInSeconds) {
      return expiredToken;
    } 
      
    let response = await fetch(`${BASE_URL}/subscribers/refresh`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${refresh_token}`
      })
    })
      .catch(error => {
        console.log("Error refreshing token", error);
        setErrorStatus(true);
      })
    let data = await response.json();
    if (data.token) {
      setWebToken(data.token);
      setToken({ token: data.token });
      setTokenState('valid');
      return ({ token: data.token, tokenStatus: 'valid' });
    }
    
  }, [])

  const handleView = useCallback((v) => {
    setView(v);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  const deleteAccount = useCallback(async () => {
    const { token, tokenStatus } = await getToken();
    if (isTokenValid(tokenStatus)) {
      try {
        const res = await fetch(BASE_URL + '/subscribers/delete', {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          })
        })
        if (res.status === 200) {
          setLoading(false);
          setMessage('Account deleted, you will now be logged out.')
          return { status: res.status };
        }
      }
      catch (e) {
        setErrorStatus(true);
        setLoading(false);
      }
    }
  }, [getToken, setLoading])

  const ssoLogin = useCallback(async (response, endpoint) => {
    let headers = new Headers({ 'Content-Type': 'application/json' })

    switch (endpoint) {
      case 'google-signin':
        headers.append('Google-Token', response.tokenId)
        break;
      case 'facebook-signin':
        headers.append('Facebook-Token', response.accessToken)
        headers.append('User-ID', response.userID)
        break;
      default:
        console.log("Sign in process for" + endpoint + "is not implemented yet.")
    }

    setLoading(true);
    try {
      const res = await fetch(`${BASE_URL}/consumers/${endpoint}`, {
        method: 'GET',
        headers,
      })
      if (res.status === 200) {
        const { token, refresh_token} = await res.json()
        const parsedToken = decipherToken(token);
        const userType = parsedToken.type;
        if (isUserConsumer(userType) || isUserMerchant(userType)) {
          // getToken();
          setToken({ token, refresh_token })
          setMessage('');
          setUserType(userType);
        } else {
          setErrorStatus(true);
        }
      } else {
        setMessage('Not able to log in with current method.');
        setMessageModal(true);
      }
    }
    catch (e) {
      console.log(e)
      setErrorStatus(true);
    }
    finally {
      setLoading(false);
    }
  }, [setMessage, setMessageModal, setErrorStatus, setLoading, setUserType])

 
  const sendSlack = useCallback(async (message) => {
    setLoading(true);
    let msg = `Environment: ${window.location.hostname}\n${message}`
    try {
      const res = await fetch(process.env.REACT_APP_SLACK_URL, {
          method: 'POST',
          body: JSON.stringify({
              text: msg
          })
      })
      if (res.status !== 200) {
        setMessage('There was a problem sending message.')
        setMessageModal(true);
        return;
      }
      setMessage('Message received. A team member will be in touch with you soon.');
      setMessageModal(true);
      return;
    } 
    catch(e) {
      console.log('Error sending slack', e)
      setErrorStatus(true);
    }
    finally {
      setLoading(false)
    }
  }, [setLoading])


  useEffect(() => {
    if (webToken === null) {
      getToken();
    }
    if (tokenState === 'valid' && webToken && dashboardData === null) {
      if (isUserConsumer(userType)) {
        fetchDashboardData({ token: webToken });
        fetchCardsData({ token: webToken });
        fetchAccountsData({ token: webToken });
        fetchProfileData({ token: webToken });
        fetchOffersData({ token: webToken, type: 'consumers' });
        fetchOffersHistory({ token: webToken });
        fetchPDFdata({ token: webToken });
        // fetchCreditInfo({ token: webToken });
      }

      if (isUserMerchant(userType)) {
        fetchOffersData({ token: webToken, type: 'merchants' });
        fetchProfileData({ token: webToken, endpoint: 'subscribers' });
      }
      if (!isAndroid && !isIOS) {
        setMobileModal(false);
      }
    }
  },[tokenState, webToken, dashboardData, userType, getToken, fetchAccountsData, fetchCardsData, 
    fetchDashboardData, fetchOffersData, fetchProfileData, fetchCreditInfo, fetchOffersHistory, fetchPDFdata])

  const consumerOptInData = optInData
    ?  <ConsumerDashboard
          view={view} 
          setView={handleView} 
          dashboardData={dashboardData} 
          optInData={optInData}
          companyList={companyList} 
          webToken={webToken}
          fetchDashboardData={fetchDashboardData}
          fetchCardsData={fetchCardsData}
          fetchAccountsData={fetchAccountsData}
          fetchProfileData={fetchProfileData}
          cardData={cardData}
          accountData={accountData}
          trackingData={trackingData}
          getToken={getToken}
          profileData={profileData}
          offersData={offersData}
          setErrorStatus={setErrorStatus}
          deleteAccount={deleteAccount}
          handleLogout={handleLogout}
          message={message}
          setMessage={setMessage}
          messageModal={messageModal}
          setMessageModal={setMessageModal}
          userType={userType}
          creditInfo={creditInfo}
          fetchCreditInfo={fetchCreditInfo}
          setContactUs={setContactUs}
          fetchOffersHistory={fetchOffersHistory}
          offersDataHistory={offersDataHistory}
          fetchOffersData={fetchOffersData}
          pdfData={pdfData}
          sendSlack={sendSlack}
      />
    : <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30}/></LoadingContainer>

  
  const dashboardSwitch = () => {
    switch (userType) {

      case USER_TYPES.CONSUMER:
        return (
          consumerOptInData
        )

      case USER_TYPES.MERCHANT:
        return (
          <MerchantDashboard
            view={view}
            setView={setView}
            dashboardData={dashboardData}
            getToken={getToken}
            fetchOffersData={fetchOffersData}
            profileData={profileData}
            message={message}
            setMessage={setMessage}
            setErrorStatus={setErrorStatus}
            deleteAccount={deleteAccount}
            handleLogout={handleLogout}
            userType={userType}
            messageModal={messageModal}
            setMessageModal={setMessageModal}
            fetchProfileData={fetchProfileData}
          />
        )

      default: return null;
    }
  }

  const loggedIn = tokenState === 'expired' || webToken === null
    ? <Login
      tokenState={tokenState}
      handleLogout={handleLogout}
      message={message}
      setMessage={setMessage}
      getToken={getToken}
      setUserType={setUserType}
      setErrorStatus={setErrorStatus}
      messageModal={messageModal}
      setMessageModal={setMessageModal}
      ssoLogin={ssoLogin}
      sendSlack={sendSlack}
    />
    : dashboardData
      ? <>
        <Nav
          handleLogout={handleLogout}
          view={view}
          setView={handleView}
          userType={userType}
          profileData={profileData}
        />
        {dashboardSwitch()}
      </>
      : <LoadingContainer><Loading type={'spin'} color={'#424242'} height={30} width={30} /></LoadingContainer>

  const showError = errorStatus
    ? <Modal
      isOpen={errorStatus}
      style={modalStyles}
      contentLabel="Notification"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ maxWidth: '21rem' }}>
          Sorry, an error occured please try again our contact us at &nbsp;
          </Message>
        <Link onClick={() => window.open("mailto:customerservice@mybitdoor.com", "_blank")}>customerservice@mybitdoor.com</Link>
        {/* {modalMessage && <Message style={{maxWidth: '21rem'}}>{modalMessage}</Message>} */}
        <Row>
          {dashboardData && <Button onClick={() => { setErrorStatus(false); setView('support'); setMessage('') }}>Support</Button>}
          <Button onClick={() => { setErrorStatus(false); setMessage('') }}>Close</Button>
        </Row>
      </ModalContent>
    </Modal>
    : null

  const showMessage = messageModal
    ? <Modal
      isOpen={messageModal}
      style={modalStyles}
      contentLabel="Notification"
      overlayClassName="modal-overlay"
    >
      <ModalContent>
        <Message style={{ maxWidth: '16rem', minWidth: '10rem' }}>
          {message} &nbsp;
          </Message>
        <Row>
          {contactUs &&
            <Button
              style={{ margin: '0 1rem 0 0' }}
              onClick={() => {
                setMessage('');
                setView('support');
                setMessageModal(false);
                setContactUs(false);
              }}>
              Contact
              </Button>}
          {resetPw &&
            <Button
              style={{ margin: '0 1rem 0 0' }}
              onClick={() => {
                setMessage('');
                window.location.replace('/#/set-pw')
                setMessageModal(false);
                setResetPw(false);
              }}>
              Reset Password
              </Button>}
          <Button
            style={{ margin: '0' }}
            onClick={() => {
              setMessage('');
              setMessageModal(false);
              setContactUs(false);
              setResetPw(false);
            }}>Ok</Button>
        </Row>
      </ModalContent>
    </Modal>
    : null

  const androidLink = () => { window.open('https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.mybitdoor.app', '_blank') }
  const appleLink = () => { window.open('https://itunes.apple.com/us/app/id1530060661', '_blank') }

  const showMobile = isIOS || isAndroid
    ? <Modal
      isOpen={mobileModal}
      style={modalStylesI}
      contentLabel="Notification"
      overlayClassName="modal-overlay"
    >
      <ModalContent style={{ maxHeight: '50rem' }}>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Link><FontAwesomeIcon onClick={() => setMobileModal(false)} icon="times" /></Link>
        </Row>
        <Message style={{ maxWidth: '16rem', minWidth: '10rem', margin: '1rem 2rem' }}>
          Our app is available on the app store with your device.
          We reccommend installing our mobile app for the best experience.
          </Message>
        <Column>
          {isAndroid && <Image style={{ width: '12rem' }} onClick={() => androidLink()} src={googleApp} />}
          {isIOS && <Image style={{ width: '10.5rem' }} onClick={() => appleLink()} src={appleApp} />}
        </Column>
      </ModalContent>
    </Modal>
    : null

  return (
    <Theme>
      <Router>
        <Switch>
          <Route exact path='/'>
            <Container>
              {loggedIn}
              {showError}
              {showMessage}
              <Mobile>
                {showMobile}
              </Mobile>
            </Container>
          </Route>
          <Route exact path='/signup'>
            <Container>
              <Signup
                message={message}
                setMessage={setMessage}
                webToken={webToken}
                setErrorStatus={setErrorStatus}
                setMessageModal={setMessageModal}
                emailSt={emailSt}
                setEmailSt={setEmailSt}
                ssoLogin={ssoLogin}
                setResetPw={setResetPw}
              />
              {showError}
              {showMessage}
            </Container>
          </Route>
          <Route exact path='/merchants-signup'>
            <Container>
              <SignupMerchant
                message={message}
                setMessage={setMessage}
                webToken={webToken}
                setErrorStatus={setErrorStatus}
              />
            </Container>
          </Route>
          <Route exact path='/set-pw'>
            <Container>
              <ResetPassword
                message={message}
                setMessage={setMessage}
                setErrorStatus={setErrorStatus}
                emailSt={emailSt}
              />
            </Container>
          </Route>
          <Route exact path='/reset-failed'>
            <Container>
              <ResetFailed />
            </Container>
          </Route>
          <Route exact path='/reset-expired'>
            <Container>
              <ResetExpired />
            </Container>
          </Route>
          <Route exact path='/reset-confirmation'>
            <Container>
              <ResetConfirmation />
            </Container>
          </Route>
          <Route exact path='/terms'>
            <Container>
              <Terms />
            </Container>
          </Route>
          <Route path='/account/:qstr'>
            <Container>
              <Account />
            </Container>
          </Route>
          <Route path='/*'>
            <Container>
              <NoPage />
            </Container>
          </Route>
        </Switch>
      </Router>
    </Theme>
  );
}

export default isLoading(App);


const Container = styled.div`
  font-size: .8rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  height: auto;
  width: 100%;
  margin: auto;
  @media (max-width: 768px) {
    height: auto;
  }
  @media (max-width: 375px) {
    background-color: #fff;
  }
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100vh;
  width: 100%;
`

const Message = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.1em;
  margin: 1rem 2rem 2rem 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`

const ModalContent = styled.div`
  ${({ theme }) => theme.animations.fadeUp};
  max-height: 40rem;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
`

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const Button = styled.button`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1em;
  height: 2rem;
  padding: .4rem 1.2rem;
  margin: 1rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonPrimary};
    color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`

const Link = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`

const Image = styled.img`
  max-width: 12rem;
  margin-bottom: 1rem;
`